// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit';

const initialData = () => {
    const item = window.localStorage.getItem('filters');
    //** Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : {};
};

export const filtersSlice = createSlice({
    name: 'filters',
    initialState: initialData(),
    reducers: {
        setFilters: (state, action) => {
            const st = { ...state, ...action.payload };
            localStorage.setItem('filters', JSON.stringify(st));
            return st;
        },
        removeFilters: state => {
            state.filters = {};
            localStorage.removeItem('filters');
        },
    },
});

export const { setFilters, removeFilters } = filtersSlice.actions;

export default filtersSlice.reducer;
