// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit';

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt';

const config = useJwt.jwtConfig;

export const loaderSlice = createSlice({
    name: 'loader',
    initialState: false,
    reducers: {
        showLoader: (state, action) => {
            state = true;
            return state;
        },
        hideLoader: state => {
            state = false;
            return state;
        },
    },
});

export const { showLoader, hideLoader } = loaderSlice.actions;

export default loaderSlice.reducer;
