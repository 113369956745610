// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit';

export const editCompanySlice = createSlice({
    name: 'editCompany',
    initialState: {},
    reducers: {
        editCompany: (state, action) => {
            const st = { ...state, ...action.payload };
            //localStorage.setItem('editCompanyData', JSON.stringify(st));
            return st;
        },
        removeEditCompany: state => {
            state = {};
            //localStorage.removeItem('editCompanyData');
            return {};
        },
    },
});

export const { editCompany, removeEditCompany } = editCompanySlice.actions;

export default editCompanySlice.reducer;
