// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit';

export const companySlice = createSlice({
    name: 'company',
    initialState: {},
    //initialState:  initialData(),
    reducers: {
        addCompany: (state, action) => {
            const st = { ...state, ...action.payload };
            localStorage.setItem('companyData', JSON.stringify(st));
            return st;
        },
        removeCompany: state => {
            state = {};
            localStorage.removeItem('companyData');
            return {};
        },
    },
});

export const { addCompany, removeCompany } = companySlice.actions;

export default companySlice.reducer;
