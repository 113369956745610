export const STAGING_INTEGRATION_URL = 'https://integrations-staging-x6nbghnnrq-uc.a.run.app';
export const STAGING_DATA_URL = 'https://data-staging-x6nbghnnrq-uc.a.run.app';
export const STAGING_ADMIN_URL = 'https://admin-staging-x6nbghnnrq-uc.a.run.app';
export const STAGING_WEBSITES_URL = 'https://websites-staging-x6nbghnnrq-uc.a.run.app';

export const PRO_INTEGRATION_URL = 'https://integrations-production-x6nbghnnrq-uc.a.run.app';
export const PRO_DATA_URL = 'https://data-production-x6nbghnnrq-uc.a.run.app';
export const PRO_ADMIN_URL = 'https://admin-production-x6nbghnnrq-uc.a.run.app';
export const PRO_WEBSITES_URL = 'https://websites-production-x6nbghnnrq-uc.a.run.app';

export const DEV_INTEGRATION_URL = 'https://integrations-dev-x6nbghnnrq-uc.a.run.app';
export const DEV_DATA_URL = 'https://data-dev-x6nbghnnrq-uc.a.run.app';
export const DEV_ADMIN_URL = 'https://admin-dev-x6nbghnnrq-uc.a.run.app';
export const DEV_WEBSITES_URL = 'https://websites-dev-x6nbghnnrq-uc.a.run.app';

export const TESTING_INTEGRATION_URL = 'https://integrations-testing-x6nbghnnrq-uc.a.run.app';
export const TESTING_DATA_URL = 'https://data-testing-x6nbghnnrq-uc.a.run.app';
export const TESTING_ADMIN_URL = 'https://admin-testing-x6nbghnnrq-uc.a.run.app';
export const TESTING_WEBSITES_URL = 'https://websites-testing-x6nbghnnrq-uc.a.run.app';

export const ADMIN_URL = 'http://localhost:3002';
export const INTEGRATION_URL = 'http://localhost:3003';
export const DATA_URL = 'http://localhost:3004';
export const WEBSITES_URL = 'http://localhost:3005';
