import logo from '../assets/images/new/multihub-logo-color.svg';
import { Button, Media } from 'reactstrap';
import '@styles/base/pages/page-misc.scss';

const BugsnagErrorView = ({ clearError }) => {
    return (
        <div className="misc-wrapper">
            <a className="brand-logo" href="/">
                <Media className="small" src={logo} style={{ height: '28px' }} />
            </a>
            <div className="misc-inner p-2 p-sm-3">
                <div className="w-100 text-center">
                    <h2 className="transparent-text">Oops!</h2>
                    <h3 className="mb-1">Inform users of an error in the component tree.</h3>
                    <div className="mb-2"> Use clearError to reset ErrorBoundary state and re-render child tree.</div>
                    <Button color="primary" className=" mb-2" onClick={clearError}>
                        Reset
                    </Button>
                </div>
            </div>
        </div>
    );
};
export default BugsnagErrorView;
