// ** React Imports
import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';

// ** Redux Imports
import { Provider } from 'react-redux';
import { store } from './redux/store';

// ** Intl & ThemeColors Context
import { ToastContainer } from 'react-toastify';
import { ThemeContext } from './utility/context/ThemeColors';
// ** slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner';

// ** Ripple Button
import './@core/components/ripple-button';

// ** PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-jsx.min';

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css';

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss';

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css';
import './@core/scss/core.scss';
import './assets/scss/style.scss';
import 'axios-progress-bar/dist/nprogress.css';

// ** Service Worker
import * as serviceWorker from './serviceWorker';
//react-query
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { appType } from './utility/Utils';
// bugsnag
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import BugsnagErrorView from './views/bugsnagError';
//GTM
import TagManager from 'react-gtm-module'
const tagManagerArgs = {
    gtmId: 'GTM-W3D8JSL'
}
 
TagManager.initialize(tagManagerArgs)

// ** Lazy load app
const LazyApp = lazy(() => import('./App'));
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 0,
            refetchOnWindowFocus: false,
        },
    },
});

const bugsnagClient = bugsnag.start({
    apiKey: 'a3008b36a51c0d1d6d146e94f95c6cb1',
    plugins: [new bugsnagReact()],
    appType: appType(),
});
//bugsnagClient.use(bugsnagReact, React)
const ErrorBoundaryBugSnag = bugsnag.getPlugin('react').createErrorBoundary(React);

ReactDOM.render(
    <ErrorBoundaryBugSnag FallbackComponent={BugsnagErrorView}>
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <Suspense fallback={<Spinner />}>
                    <ThemeContext>
                        <LazyApp />
                        <ToastContainer newestOnTop />
                    </ThemeContext>
                </Suspense>
            </Provider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    </ErrorBoundaryBugSnag>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
