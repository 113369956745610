// ** Reducers Imports
import navbar from './navbar';
import layout from './layout';
import auth from './authentication';
import starting from './starting';
import company from './company';
import filters from './filters';
import editCompany from './editCompany';
import editProperty from './editProperty';
import loader from './loading';

const rootReducer = {
    auth,
    navbar,
    layout,
    starting,
    company,
    filters,
    editCompany,
    editProperty,
    loader,
};

export default rootReducer;
