// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit';

export const editPropertySlice = createSlice({
    name: 'editProperty',
    initialState: {},
    reducers: {
        editProperty: (state, action) => {
            const st = { ...state, ...action.payload };
           // localStorage.setItem('editPropertyData', JSON.stringify(st));
            return st;
        },
        removeEditProperty: state => {
            state = {};
           // localStorage.removeItem('editPropertyData');
            return {};
        },
    },
});

export const { editProperty, removeEditProperty } = editPropertySlice.actions;

export default editPropertySlice.reducer;
