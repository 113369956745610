// ** Logo
import logo from '@src/assets/images/logo/image.webp';

const SpinnerComponent = () => {
    return (
        // <div className='fallback-spinner app-loader'>
        //   <img className='fallback-logo' src={logo} alt='logo' />
        //   <div className='loading'>
        //     <div className='effect-1 effects'></div>
        //     <div className='effect-2 effects'></div>
        //     <div className='effect-3 effects'></div>
        //   </div>
        // </div>
        (<div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
            <div className="fallback-spinner">
                <div className="loading">
                    <div className="effect-1 effects"></div>
                    <div className="effect-2 effects"></div>
                    <div className="effect-3 effects"></div>
                </div>
            </div>
        </div>)
    );
};

export default SpinnerComponent;
